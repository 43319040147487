export const REASON_MAPPING = {
  other: 'Anders',
  item_expired: 'Artikel bedorven',
  item_elsewhere_cheaper: 'Artikel elders goedkoper',
  item_received_too_late: 'Artikel heb ik te laat ontvangen',
  item_damaged: 'Artikel is beschadigd',
  item_too_big: 'Artikel is te groot',
  item_too_small: 'Artikel is te klein',
  item_color_different: 'Artikel kleur is anders dan verwacht',
  item_not_match_description: 'Artikel komt niet overeen met omschrijving/afbeelding(en)',
  item_not_liked: 'Artikel staat me niet',
  item_quality_not_meet_expectations: 'Artikel voldoet niet aan de verwachte kwaliteit',
  item_not_meet_expectations: 'Artikel voldoet niet aan de verwachtingen',
  item_working_different: 'Artikel werkt anders dan verwacht',
  item_not_working: 'Artikel werkt niet',
  better_price: 'Betere prijs beschikbaar',
  no_reason: 'Geen reden',
  wrong_item_received: 'Ik heb een verkeerd artikel ontvangen',
  wrong_size_received: 'Ik heb een verkeerde maat/afmetingen ontvangen',
  dont_need_product_anymore: 'Ik heb het product niet meer nodig',
  wrong_product_ordered: 'Ik heb het verkeerde product besteld',
  changed_mind: 'Ik heb me bedacht',
  more_items_received: 'Ik heb meerdere artikelen ontvangen, de juiste houd ik',
  exchange_product: 'Ik wil het product ruilen',
};

export const getReasonKey = value => Object.keys(REASON_MAPPING).find(key => REASON_MAPPING[key] === value) || value;
