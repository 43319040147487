<template>
  <b-autocomplete
    v-bind="$attrs"
    id="state-province-code-autocomplete"
    v-model="value"
    append-to-body
    autocomplete="new-address-level1"
    :data="filteredStates"
    field="name"
    keep-first
    open-on-focus
    @select="onSelect"
  >
    <template v-slot="{ option }">
      <option :id="`state-${option.isoCode}`" :value="option.name">{{ option.name }}</option>
    </template>
    <template v-if="!filteredStates.length" #empty>Staat/Provincie niet gevonden</template>
  </b-autocomplete>
</template>

<script setup>
  import { State } from 'country-state-city';
  import { computed } from 'vue';

  const props = defineProps({
    value: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: 'NL',
    },
  });

  const emit = defineEmits(['input']);

  const value = computed({
    get: () => props.value || '',
    set: value => emit('input', value),
  });

  const availableStatesForSelectedCountry = computed(() =>
    ['CA', 'US', 'CN'].includes(props.country) ? State.getStatesOfCountry(props.country) : [],
  );

  const filteredStates = computed(() => {
    const searchString = value.value.toLowerCase();
    return availableStatesForSelectedCountry.value.filter(state =>
      JSON.stringify(state).toLowerCase().includes(searchString),
    );
  });

  const onSelect = option => {
    if (!option) return;
    value.value = option.name;
  };
</script>
