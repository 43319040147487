export class Contact {
  static FIELDS = [
    'name',
    'company_name',
    'country',
    'state_province_code',
    'zipcode',
    'housenumber',
    'address_1',
    'address_2',
    'city',
    'email',
    'phone',
  ];

  /**
   * @param {ContactType} data
   */
  constructor(data) {
    this.name = data.name;
    this.company_name = data.company_name;
    this.country = data.country;
    this.state_province_code = data.state_province_code;
    this.zipcode = data.zipcode;
    this.housenumber = data.housenumber;
    this.address_1 = data.address_1;
    this.address_2 = data.address_2;
    this.city = data.city;
    this.email = data.email;
    this.phone = data.phone;
    this.id = data.id;
    this.is_active = data.is_active;
  }

  /**
   * Returns the contact details.
   * @returns {ContactType}
   */
  getFields() {
    const fields = {};
    Contact.FIELDS.forEach(field => {
      fields[field] = this[field];
    });
    return fields;
  }

  static getFieldsFrom(data) {
    const fields = {};
    Contact.FIELDS.forEach(field => {
      fields[field] = data[field];
    });
    return fields;
  }
}
