import { api } from '@/utils';

const state = {
  all: [],
};

const actions = {
  async getApiUsers({ commit }) {
    const result = await api.getApiUsers();
    commit('setApiUsers', result.data);
    return result;
  },
  async createApiUser({ commit }, data) {
    const result = await api.createApiUser(data);
    commit('setApiUser', result.data.user);
    return result;
  },
  async updateApiUser({ commit }, { id, updates }) {
    const result = await api.updateApiUser(id, updates);
    commit('setApiUser', result.data);
    return result;
  },
};

const mutations = {
  setApiUsers(state, apiUsers) {
    state.all = apiUsers;
  },
  setApiUser(state, apiUser) {
    const existing = state.all.find(o => o.id === apiUser.id);
    if (existing) {
      Object.assign(existing, apiUser);
    } else {
      state.all.push(apiUser);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
