<script setup>
  import { inject } from 'vue';
  import { CURRENCIES } from '@/constants/CURRENCIES';

  const order = inject('order');

  defineEmits(['update:currency', 'update:order_number', 'update:order_date']);
</script>
<template>
  <div>
    <b-field label="Ordernummer">
      <b-input v-model="order.order_number" autocomplete="order-number" type="text" />
    </b-field>
    <b-field label="Valuta">
      <b-select id="receiver-currency" v-model="order.currency" expanded placeholder="Selecteer valuta…">
        <option v-for="currency in CURRENCIES" :key="currency" :value="currency">
          {{ currency }}
        </option>
      </b-select>
    </b-field>
    <b-field label="Orderdatum">
      <b-datetimepicker
        v-model="order.order_date"
        autocomplete="order-date"
        :first-day-of-week="1"
        locale="nl-NL"
        type="date"
        @icon-right-click="$emit('update:order_date', new Date())"
      />
    </b-field>
  </div>
</template>
