export const DEFAULT_ORDER = {
  name: undefined,
  company_name: undefined,
  //get users current country code from browser
  country: 'NL',
  state_province_code: undefined,
  zipcode: undefined,
  housenumber: undefined,
  address_1: undefined,
  address_2: undefined,
  city: undefined,
  email: undefined,
  phone: undefined,
  currency: 'EUR',
  order_number: undefined,
  order_date: new Date(),
  order_items: [],
  order_status: undefined,
  pickup_point: undefined,
  shipment: {
    courier_id: undefined,
    shipment_type: undefined,
    courier_fields: {},
    packages: [
      {
        weight: 0,
      },
    ],
  },
};
