import { ref } from 'vue';

export function useClipboard() {
  const clipboardValue = ref('');

  const copy = async text => {
    try {
      if (navigator && navigator.clipboard) {
        await navigator.clipboard.writeText(text);
        clipboardValue.value = text;
      } else {
        legacyCopy(text);
      }
    } catch (error) {
      legacyCopy(text);
    }
  };

  const legacyCopy = text => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    clipboardValue.value = text;
  };

  return {
    copy,
    clipboardValue,
  };
}
