<script setup>
  import { reactive, set } from 'vue';
  import CountryCodeAutocomplete from '@/components/form/CountryCodeAutocomplete.vue';
  import StateProvinceCodeAutoComplete from '@/components/form/StateProvinceCodeAutoComplete.vue';
  import { useAddressCompletion } from '@/composables/useAddressCompletion';

  const props = defineProps({
    contact: {
      type: Object,
      default: () => ({
        name: '',
        company_name: '',
        country: '',
        state_province_code: '',
        zipcode: '',
        housenumber: '',
        address_1: '',
        address_2: '',
        city: '',
        email: '',
        phone: '',
      }),
    },
  });

  const emit = defineEmits(['close', 'submit']);

  const form = reactive(structuredClone(props.contact));

  const handleSubmit = () => {
    emit('submit', form);
    emit('close');
  };

  const { completeAddress, error: addressCompletionError } = useAddressCompletion();

  const triggerAddressCompletion = async () => {
    if (!form.zipcode || !form.housenumber) return;

    const { city, street } = await completeAddress(form.zipcode, form.housenumber);

    if (!addressCompletionError.value) {
      if (street) set(form, 'address_1', street);
      if (city) set(form, 'city', city);
    } else console.error(addressCompletionError);
  };
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div class="modal-card">
      <div class="modal-card-head">
        <h1 class="modal-card-title tw-font-bold">Contact aanmaken</h1>
      </div>
      <div class="modal-card-body">
        <div class="tw-grid md:tw-grid-cols-2 tw-gap-x-4 tw-gap-y-1">
          <b-field label="Naam">
            <b-input v-model="form.name" autocomplete="name" expanded />
          </b-field>
          <b-field label="Bedrijfsnaam">
            <b-input v-model="form.company_name" autocomplete="organization" />
          </b-field>
          <b-field label="Land">
            <country-code-autocomplete v-model="form.country" autocomplete="country" />
          </b-field>
          <b-field>
            <template #label>
              <span class="tw-inline-flex tw-items-center tw-gap-2">
                <span>Staat / Provincie</span>
                <b-tooltip multilined type="is-dark">
                  <font-awesome-icon class="tw-h-4 tw-w-4" icon="info-circle" />
                  <template #content>
                    Staat / Provincie enkel benodigt bij landen zoals de Verenigde Staten, Canada en China.
                  </template>
                </b-tooltip>
              </span>
            </template>
            <state-province-code-auto-complete
              v-model="form.state_province_code"
              :country="form.country"
              :disabled="!['US', 'CA', 'CN'].includes(form.country)"
              @state-valid="boolean => (stateValid = boolean)"
            ></state-province-code-auto-complete>
          </b-field>
          <b-field label="Postcode">
            <b-input v-model="form.zipcode" autocomplete="postal-code" @blur="triggerAddressCompletion" />
          </b-field>
          <b-field label="Huisnummer en toevoeging">
            <b-input v-model="form.housenumber" autocomplete="address-line2" @blur="triggerAddressCompletion" />
          </b-field>
          <b-field label="Adres regel 1">
            <b-input v-model="form.address_1" autocomplete="address-line1" />
          </b-field>
          <b-field label="Adres regel 2">
            <b-input v-model="form.address_2" autocomplete="address-line3" placeholder="Bijv. 2e verdieping" />
          </b-field>
          <b-field label="Plaats">
            <b-input v-model="form.city" autocomplete="address-level2" />
          </b-field>
          <b-field label="E-mail">
            <b-input v-model="form.email" autocomplete="email" type="email" />
          </b-field>
          <b-field label="Telefoonnummer">
            <b-input v-model="form.phone" autocomplete="tel" />
          </b-field>
        </div>
      </div>
      <div class="modal-card-foot tw-justify-between">
        <b-button native-type="button" @click="$emit('close')">Annuleren</b-button>
        <b-button class="is-primary" native-type="submit">Aanmaken</b-button>
      </div>
    </div>
  </form>
</template>
