<template>
  <ul class="tw-space-y-2 my-4">
    <li
      v-for="(item, idx) of info"
      :key="idx"
      :class="[
        'tw-p-4',
        'tw-text-white',
        'tw-rounded-2xl',
        {
          'has-background-danger': item.status === 'error',
          'has-background-warning': item.status === 'warning',
          'has-background-info': item.status === 'info',
          'has-background-success': item.status === 'success',
        },
      ]"
    >
      {{ formattedMessage(item) }}
      <slot name="action" />
    </li>
  </ul>
</template>

<script setup>
  import { STATUS_INFO_MAPPING } from '@/constants/COMMON';
  import { defineProps } from 'vue';

  const props = defineProps({
    info: Array,
  });

  const formattedMessage = value => {
    if (value.type === 'missing_shipment_logic_rule_value') return value.message;
    else if (value.type && value.field) return STATUS_INFO_MAPPING[`${value.type}_${value.field}`];
    else if (value.message) return value.message;
    return 'Onbekend';
  };
</script>
