<template>
  <b-autocomplete
    v-bind="$attrs"
    :id="$attrs.id || 'country-code-autocomplete'"
    v-model="searchQuery"
    :append-to-body="true"
    autocomplete="false"
    :data="filteredCountryList"
    field="name"
    keep-first
    @select="$emit('input', $event ? $event.code : undefined)"
  >
    <template v-slot="{ option }">
      <option :id="`${$attrs.id}-country-${option.code}`">{{ option.name }}</option>
    </template>
    <template #empty>Land niet gevonden</template>
  </b-autocomplete>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import { getName, getNames } from '@/utils/countries';

  const props = defineProps({
    value: String,
  });

  const emit = defineEmits(['input']);

  const getCountryName = value => getName(value, 'nl') || '';

  const searchQuery = ref(getCountryName(props.value));

  const countryList = computed(() => {
    return Object.entries(getNames('nl')).map(country => ({ code: country[0], name: country[1] }));
  });

  const filteredCountryList = computed(() => {
    return countryList.value.filter(country => country.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
  });

  watch(
    () => props.value,
    newValue => {
      searchQuery.value = newValue ? getCountryName(newValue) : '';
    },
  );

  watch(searchQuery, newValue => {
    if (newValue) {
      const exactMatch = countryList.value.find(country => country.name.toLowerCase() === newValue.toLowerCase());
      if (exactMatch && props.value !== exactMatch.code) {
        emit('input', exactMatch.code);
      } else if (!filteredCountryList.value.length) {
        emit('input', undefined);
      }
    } else {
      emit('input', undefined);
    }
  });
</script>
