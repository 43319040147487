import { printerClient } from '@/utils';
import { ApiError, getErrorHash } from '@/utils/errors';

const state = {
  printerClient: {
    id: null,
    isLoading: false,
    printers: [],
  },
  notifications: [],
  errors: [],
};

const getters = {
  activePrinter: state => state.printerClient.printers.find(p => p.selected),
  printerClientIsActive: state => !!state.printerClient.id,
  printerClientIsReady: (state, getters) =>
    !state.printerClient.isLoading && getters.printerClientIsActive && getters.activePrinter,
};

const actions = {
  init({ dispatch }) {
    dispatch('initPrinterClient');

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        dispatch('connectPrinterClient');
      }
    });
  },
  initPrinterClient({ dispatch }) {
    dispatch('getPrinterClientId');
    dispatch('getPrinters');
    dispatch('connectPrinterClient');
  },
  async getPrinterClientId({ commit }) {
    try {
      const id = (await printerClient.getClientId()) || null;
      commit('setPrinterClientId', id);
    } catch (error) {
      if (!(error instanceof ApiError)) {
        throw error;
      }
      // Make sure printer client is not active
      commit('resetPrinterClient');
    }
  },
  async getPrinters({ commit }) {
    commit('setPrinterClientLoading', true);
    try {
      const printers = await printerClient.getPrinters();
      commit('setPrinters', printers);
    } catch (error) {
      if (!(error instanceof ApiError)) throw error;
      // Make sure printer client is not active
      commit('resetPrinterClient');
    } finally {
      commit('setPrinterClientLoading', false);
    }
  },
  async setPrinter({ dispatch }, name) {
    const result = await printerClient.setPrinter({ name });

    if (result.status === 'Ok') dispatch('getPrinters');
  },
  async connectPrinterClient({ commit }) {
    try {
      await printerClient.connect();
    } catch (error) {
      if (!(error instanceof ApiError)) throw error;
      commit('resetPrinterClient');
    }
  },
};

const mutations = {
  createNotification(state, notification) {
    state.notifications.push(notification);
  },
  deleteNotification(state, notification) {
    const idx = state.notifications.indexOf(notification);
    state.notifications.splice(idx, 1);
  },
  createError(state, error) {
    if (!error.hash) error.hash = getErrorHash(error);
    if (!error.timestamp) error.timestamp = new Date().getTime();
    state.errors.push(error);
  },
  deleteError(state, error) {
    state.errors = state.errors.filter(e => e.hash != error.hash);
  },
  setPrinterClientId(state, id) {
    state.printerClient.id = id;
  },
  setPrinterClientLoading(state, isLoading) {
    state.printerClient.isLoading = isLoading;
  },
  resetPrinterClient(state) {
    state.printerClient.id = null;
    state.printerClient.isLoading = false;
    state.printerClient.printers = [];
  },
  setPrinters(state, printers) {
    state.printerClient.printers = printers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
