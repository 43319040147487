export const DEFAULT_ORDER_ITEM = {
  id: null,
  category: null,
  description: null,
  hs_code: null,
  name: null,
  product_country: null,
  quantity: null,
  sku: null,
  unit_price_inc_btw: null,
  weight: null,
  ean: null,
};
