<script setup>
  import CountryCodeAutocomplete from '@/components/form/CountryCodeAutocomplete.vue';
  import { inject, ref, watch } from 'vue';
  import { useClipboard } from '@/composables/useClipboard';
  import { DEFAULT_ORDER_ITEM } from '@/constants/DEFAULT_ORDER_ITEM';

  const order = inject('order');

  const selectedOrderItems = ref([]);

  const deleteOrderItem = orderItem => {
    order.value.order_items = order.value.order_items.filter(item => item !== orderItem);
    selectedOrderItems.value = selectedOrderItems.value.filter(item => item !== orderItem);
  };

  const deleteSelectedOrderItems = () => {
    order.value.order_items = order.value.order_items.filter(item => !selectedOrderItems.value.includes(item));
    selectedOrderItems.value = [];
  };

  const addOrderItem = () => {
    order.value.order_items.push(structuredClone(DEFAULT_ORDER_ITEM));
  };

  const { copy } = useClipboard();

  watch(
    order.value.order_items,
    newValue => {
      order.value.shipment.packages[0].weight = newValue.reduce((acc, item) => acc + item.quantity * item.weight, 0);
    },
    { immediate: true, deep: true },
  );
</script>

<template>
  <div>
    <header>
      <h2 class="tw-space-x-4">
        <span>Orderregels</span>
        <b-tooltip class="tw-not-prose" multilined type="is-dark">
          <font-awesome-icon class="tw-w-5 tw-h-5 tw-inline-block tw-text-amber-500" icon="exclamation-circle" />
          <template #content>
            <p>Extra kosten worden in rekening gebracht bij orders zwaarder dan 31kg</p>
          </template>
        </b-tooltip>
      </h2>
    </header>

    <section class="tw-not-prose">
      <b-table
        v-if="order.order_items.length > 0"
        checkable
        :checked-rows.sync="selectedOrderItems"
        :data="order.order_items"
        :mobile-cards="false"
        :row-class="(row, index) => `tw-group${index % 2 === 0 ? ' even' : ' odd'}`"
        scrollable
        striped
      >
        <b-table-column field="name" label="Naam">
          <template #default="{ row }">
            <b-input v-model="row.name" class="tw-w-32" type="text" />
          </template>
        </b-table-column>
        <b-table-column field="description" label="Omschrijving">
          <template #default="{ row }">
            <b-input v-model="row.description" class="tw-w-32" type="text" />
          </template>
        </b-table-column>
        <b-table-column field="quantity" label="Aantal" numeric>
          <template #default="{ row }">
            <b-input v-model="row.quantity" class="tw-w-24" type="number" />
          </template>
        </b-table-column>
        <b-table-column field="unit_price_inc_btw" :label="`Prijs per product (${order.currency})`" numeric>
          <template #default="{ row }">
            <b-input v-model="row.unit_price_inc_btw" class="tw-w-24" step="0.01" type="number" />
          </template>
        </b-table-column>
        <b-table-column :label="`Totaal prijs (${order.currency})`" numeric>
          <template #default="{ row }">
            <b-field>
              <b-input
                class="tw-w-24"
                disabled
                step="0.01"
                type="number"
                :value="(row.unit_price_inc_btw * row.quantity).toFixed(2)"
              />
              <p class="control">
                <b-button class="tw-px-3" native-type="button" @click="copy(row.unit_price_inc_btw * row.quantity)">
                  <font-awesome-icon class="tw-w-4 tw-h-4" icon="copy" />
                </b-button>
              </p>
            </b-field>
          </template>
        </b-table-column>
        <b-table-column field="weight" label="Gewicht per product (gram)" numeric>
          <template #default="{ row }">
            <b-input v-model="row.weight" class="tw-w-24" type="number" />
          </template>
        </b-table-column>
        <b-table-column label="Totaal gewicht (gram)" numeric>
          <template #default="{ row }">
            <b-field>
              <b-input class="tw-w-24" disabled type="number" :value="(row.weight * row.quantity).toFixed(0)" />
              <p class="control">
                <b-button class="tw-px-3" native-type="button" @click="copy(row.weight * row.quantity)">
                  <font-awesome-icon class="tw-w-4 tw-h-4" icon="copy" />
                </b-button>
              </p>
            </b-field>
          </template>
        </b-table-column>
        <b-table-column field="category" label="Categorie">
          <template #default="{ row }">
            <b-input v-model="row.category" class="tw-w-32" type="text" />
          </template>
        </b-table-column>
        <b-table-column field="sku">
          <template #header>
            <div class="tw-flex tw-items-center tw-gap-1">
              SKU-code
              <b-tooltip append-to-body multilined type="is-dark">
                <span class="tw-flex tw-items-center tw-gap-1">
                  <font-awesome-icon class="tw-w-4 tw-h-4" icon="info-circle" />
                </span>
                <template v-slot:content>
                  <div class="tw-space-y-2 tw-text-left tw-text-balance">
                    <p>Gebruik een unieke en duidelijke SKU-code.</p>
                    <p>
                      Vermijd het gebruik van speciale tekens, spaties, en een formaat waarin de cijfers 0 en 1 kunnen
                      worden verward met de letters O en I.
                    </p>
                    <p>
                      Zorg ervoor dat de lengte niet onnodig lang is. Je kunt alle cijfers gebruiken of een mix van
                      cijfers en letters.
                    </p>
                  </div>
                </template>
              </b-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <b-input v-model="row.sku" class="tw-w-32" type="text" />
          </template>
        </b-table-column>
        <b-table-column field="hs_code">
          <template #header>
            <span class="tw-flex tw-items-center tw-gap-1">
              HS-code
              <a
                class="tw-w-4 tw-h-4 tw-flex tw-items-center"
                href="https://www.wcoomd.org/en/topics/nomenclature/instrument-and-tools/hs-nomenclature-2022-edition/hs-nomenclature-2022-edition.aspx"
                target="_blank"
              >
                <b-tooltip type="is-dark">
                  <font-awesome-icon class="tw-w-4 tw-h-4" icon="external-link" />
                  <template #content>Klik hier voor meer informatie over HS-codes</template>
                </b-tooltip>
              </a>
            </span>
          </template>
          <template #default="{ row }">
            <b-input v-model="row.hs_code" class="tw-w-32" type="text" />
          </template>
        </b-table-column>
        <b-table-column field="product_country" label="Land van herkomst">
          <template #default="{ row }">
            <country-code-autocomplete v-model="row.product_country" class="tw-w-32" />
          </template>
        </b-table-column>
        <b-table-column field="ean_code">
          <template #header>
            <div class="tw-flex tw-items-center tw-gap-1">
              EAN-code
              <b-tooltip append-to-body multilined type="is-dark">
                <span class="tw-flex tw-items-center tw-gap-1">
                  <font-awesome-icon class="tw-w-4 tw-h-4" icon="info-circle" />
                </span>
                <template #content>
                  <div class="tw-space-y-2 tw-text-left tw-text-balance">
                    <p>Voer een geldige EAN-code in (8 of 13 cijfers).</p>
                    <p>
                      EAN-codes zijn unieke productidentificaties die worden gebruikt voor scanning en inventarisbeheer.
                    </p>
                    <p>Alleen numerieke tekens zijn toegestaan.</p>
                  </div>
                </template>
              </b-tooltip>
            </div>
          </template>
          <template #default="{ row }">
            <b-input v-model="row.ean_code" class="tw-w-32" type="text" />
          </template>
        </b-table-column>
        <b-table-column
          cell-class="!tw-border-l-2 tw-w-14 tw-space-x-2 !tw-border-l group-[.odd]:tw-bg-[#fafafa] group-hover:!tw-bg-[whitesmoke] tw-sticky tw-right-0  tw-bg-white"
          header-class="!tw-border-l-2 tw-w-14 tw-space-x-2 !tw-border-l group-[.odd]:tw-bg-[#fafafa] group-hover:!tw-bg-[whitesmoke] tw-sticky tw-right-0  !tw-bg-white"
          label="Acties"
        >
          <template #default="{ row }">
            <b-button class="tw-p-3" type="is-danger" @click="deleteOrderItem(row)">
              <font-awesome-icon class="tw-w-4 tw-h-4" icon="trash" />
            </b-button>
          </template>
        </b-table-column>
      </b-table>
    </section>

    <aside class="tw-sticky tw-top-[155px] tw-z-10 tw-bg-white tw-p-4">
      <div class="tw-space-x-4">
        <b-button id="add-order-item-button" icon-left="math-plus" @click="addOrderItem"> Voeg regel toe </b-button>
        <b-button
          :disabled="selectedOrderItems.length <= 0"
          icon-left="trash"
          type="is-danger"
          @click="deleteSelectedOrderItems"
        >
          Verwijder regels
        </b-button>
      </div>
    </aside>
  </div>
</template>
