var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"tw-space-y-2 my-4"},_vm._l((_vm.info),function(item,idx){return _c('li',{key:idx,class:[
      'tw-p-4',
      'tw-text-white',
      'tw-rounded-2xl',
      {
        'has-background-danger': item.status === 'error',
        'has-background-warning': item.status === 'warning',
        'has-background-info': item.status === 'info',
        'has-background-success': item.status === 'success',
      },
    ]},[_vm._v(" "+_vm._s(_setup.formattedMessage(item))+" "),_vm._t("action")],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }