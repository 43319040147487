import { computed, getCurrentInstance } from 'vue';
import ContactFormModal from '@/components/modals/ContactFormModal.vue';
import { Contact } from '@/classes/Contact';

export const useContact = () => {
  const instance = getCurrentInstance();

  const store = instance.proxy.$store;
  const buefy = instance.proxy.$buefy;

  const contacts = computed(() => store.state.contact.contacts);

  /**
   * Builds a message for the delete confirmation dialog.
   * @param {Contact[]} contacts - The contacts to be deleted.
   * @returns {string} The message to display in the confirmation dialog.
   */
  const buildDeleteMessage = contacts => {
    if (contacts.length === 1) {
      return `Weet je zeker dat je <strong>${contacts[0].name}</strong> wilt verwijderen?`;
    } else {
      const names = `<ul class="tw-list-disc tw-m-auto tw-list-inside">${contacts.map(c => `<li>${c.name}</li>`).join('')}</ul>`;
      return `Weet je zeker dat je de onderstaande contacten wilt verwijderen?<br/><br/>${names}`;
    }
  };

  /**
   * Initializes the contacts by dispatching the 'contact/read' action.
   * @returns {Promise<void>}
   */
  const initContact = async () => await store.dispatch('contact/read');

  /**
   * Opens a contact modal with the given options.
   * @param {object} [options={}] - The options for the modal.
   * @param {object} [options.props] - The props to pass to the ContactFormModal component.
   * @param {string} options.action - The Vuex action to dispatch when the form is submitted.
   * @param {string} options.message - The message to display in the toast after the form is submitted.
   * @returns {Promise<object>} A promise that resolves with the contact data when the form is submitted.
   */
  const openContactModal = (options = {}) => {
    return new Promise(resolve => {
      buefy.modal.open({
        parent: instance.proxy,
        component: ContactFormModal,
        trapFocus: true,
        hasModalCard: true,
        props: options.props,
        events: {
          submit: async form => {
            const contact = await store.dispatch(options.action, form);
            buefy.toast.open({ message: options.message, type: 'is-success' });
            resolve(contact);
          },
        },
      });
    });
  };

  /**
   * Creates a contact, either via a modal form or directly with provided data.
   * @param {object} [options={}] - The options for creating the contact.
   * @param {object} [options.form] - Optional form data. If provided, the modal is skipped.
   * @param {Function} [options.callback] - Optional callback function to execute after contact creation.
   * @returns {Promise<Contact>} A promise that resolves with the created contact.
   */
  const createContact = async (options = {}) => {
    let contact;
    const { form, callback } = options;

    if (form) {
      // Create contact directly with provided form data
      contact = await store.dispatch('contact/create', form);
      buefy.toast.open({ message: 'Contact aangemaakt', type: 'is-success' });
    } else {
      // Open the modal form
      contact = await openContactModal({
        action: 'contact/create',
        message: 'Contact aangemaakt',
      });
    }

    if (typeof callback === 'function') callback?.(contact);
    return contact;
  };

  /**
   * Deletes one or more contacts after confirmation.
   * @param {Contact|Contact[]} contactsToDelete - The contact or array of contacts to delete.
   * @returns {Promise<void>}
   */
  const deleteContacts = async contactsToDelete => {
    if (!Array.isArray(contactsToDelete)) contactsToDelete = [contactsToDelete];

    buefy.dialog.confirm({
      title: 'Contact verwijderen',
      message: buildDeleteMessage(contactsToDelete),
      onConfirm: async () => {
        await store.dispatch('contact/delete', contactsToDelete);
        buefy.toast.open({ message: `${contactsToDelete.length} contacten verwijderd`, type: 'is-success' });
      },
      confirmText: 'Ja',
      cancelText: 'Nee',
    });
  };

  /**
   * Updates a contact using the openContactModal function.
   * @param {object} contact - The contact object to be updated.
   * @returns {Promise<any>} A promise that resolves with the result of the openContactModal function.
   */
  const updateContact = async (options = {}) => {
    const { contact, callback } = options;
    const updatedContact = await openContactModal({
      props: { contact },
      action: 'contact/update',
      message: 'Contact aangepast',
    });

    if (typeof callback === 'function') callback?.(contact);

    return contact;
  };

  return { contacts, createContact, deleteContacts, updateContact, initContact };
};
