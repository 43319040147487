import { api } from '@/utils';

class ContactStoreError extends Error {
  constructor(message, response) {
    super(message);
    this.response = response;
  }
}

/**
 * @typedef {Object} Contact
 * @property {string} name
 * @property {string} company_name
 * @property {string} country
 * @property {string} state_province_code
 * @property {string} zipcode
 * @property {string} housenumber
 * @property {string} address_1
 * @property {string} address_2
 * @property {string} city
 * @property {string} email
 * @property {string} phone
 * @property {string} id
 * @property {boolean} is_active
 */

const state = {
  contacts: [],
  isLoading: false,
};

const getters = {};

const actions = {
  async read({ commit }) {
    commit('setLoading', true);
    try {
      const response = await api.getContacts();
      commit('addContacts', response.data);
    } catch (error) {
      console.error(error);
      throw new ContactStoreError('Failed to read address book', error.response);
    } finally {
      commit('setLoading', false);
    }
  },
  async search({ commit }, search) {
    commit('setLoading', true);
    try {
      const response = await api.getContacts({ search });
      commit('addContacts', response.data);
    } catch (error) {
      console.error(error);
      throw new ContactStoreError('Failed to search address book', error.response);
    } finally {
      commit('setLoading', false);
    }
  },
  async create({ dispatch, commit }, address) {
    try {
      const response = await api.createContact(address);
      commit('addContacts', response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw new ContactStoreError('Failed to create address', error.response);
    }
  },
  async update({ dispatch, commit }, contact) {
    try {
      await api.updateContact(contact);
      commit('updateContact', contact);
      return contact;
    } catch (error) {
      console.error(error);
      throw new ContactStoreError('Failed to update address', error.response);
    }
  },
  async delete({ dispatch, commit }, contacts) {
    const ids = Array.isArray(contacts) ? contacts.map(contact => contact.id) : [contacts.id];
    try {
      await api.deleteContact(ids);
      commit('removeContacts', ids);
    } catch (error) {
      console.error(error);
      throw new ContactStoreError('Failed to delete address', error.response);
    }
  },
};

const mutations = {
  addContacts(state, contacts) {
    const contactsArray = Array.isArray(contacts) ? contacts : [contacts];
    contactsArray.forEach(contact => {
      if (!state.contacts.some(c => c.id === contact.id)) {
        state.contacts.push(contact);
      }
    });
    state.contacts.sort((a, b) => a.name.localeCompare(b.name));
  },
  updateContact(state, contact) {
    Object.assign(
      state.contacts.find(c => c.id === contact.id),
      contact,
    );
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  removeContacts(state, ids) {
    state.contacts = state.contacts.filter(contact => !ids.includes(contact.id));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
