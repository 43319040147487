<template>
  <div>
    <base-tab
      item-name="bestelling"
      item-name-plural="bestellingen"
      :loadView="loadView"
      :updateView="updateView"
      :view="view"
      @select="selectedOrders = $event"
      @sort="sort = $event"
    >
      <template #controls>
        <div class="level-item">
          <b-button aria-label="update" :disabled="view.isLoading || isLoading" @click="update">
            <span class="tw-inline-flex tw-gap-2">
              <font-awesome-icon
                class="tw-w-4 tw-h-4"
                :class="{
                  'tw-animate-spin': view.isLoading || isLoading,
                }"
                icon="sync"
              />
              <span>Update</span>
            </span>
          </b-button>
        </div>
        <div v-if="controls" class="level-item">
          <!-- labelIds are just a dummy to give print controls something to work with. Will be updated to actual label IDs by callback. -->
          <print-controls context="order" :order-ids="selectedOrderIds" />
        </div>
        <div class="level-item">
          <b-field>
            <p class="control">
              <b-tooltip type="is-dark">
                <template #content> Bestellingen verwijderen </template>
                <b-button class="tw-p-3" :disabled="!selectedOrders.length > 0" @click="deactivateBulkOrders">
                  <span class="tw-flex tw-items-center tw-gap-1">
                    <font-awesome-icon class="tw-w-4 tw-h-4" icon="trash" />
                    <span class="tw-sr-only">Bestellingen verwijderen</span>
                  </span>
                </b-button>
              </b-tooltip>
            </p>
            <p class="control">
              <b-tooltip type="is-dark">
                <template #content> Verzendregels opnieuw toepassen </template>
                <b-button class="tw-p-3" :disabled="!selectedOrders.length > 0" @click="resetOrders">
                  <span class="tw-inline-flex tw-gap-2">
                    <font-awesome-icon class="tw-w-4 tw-h-4" icon="gavel" />
                    <span class="tw-sr-only">Verzendregels opnieuw toepassen</span>
                  </span>
                </b-button>
              </b-tooltip>
            </p>
          </b-field>
        </div>
      </template>

      <template #table>
        <b-table-column #default="{ row: order }" field="webshop" label="Shop" sortable>
          <web-shop :item="order" />
        </b-table-column>
        <b-table-column #default="{ row: order }" field="order_number" label="Ordernummer" numeric sortable>
          <Copy :message="`${order.order_number} gekopieerd naar klembord`" :value="order.order_number">
            <span class="tw-inline-block tw-max-w-[16ch] tw-tabular-nums tw-overflow-hidden tw-text-ellipsis">
              {{ order.order_number }}
            </span>
          </Copy>
        </b-table-column>
        <b-table-column #default="{ row: order }" field="status" label="Status" sortable>
          <order-status :order="order" />
        </b-table-column>
        <b-table-column #default="{ row: order }" field="order_status" label="Shop status" sortable>
          <span v-if="order.order_status" class="tag">{{ order.order_status }}</span>
        </b-table-column>
        <b-table-column #default="{ row: order }" field="name" label="Naam" sortable>
          <Copy :message="`${order.name} gekopieerd naar klembord`" :value="order.name">
            <span class="tw-inline-block tw-max-w-[16ch] tw-tabular-nums tw-overflow-hidden tw-text-ellipsis">
              {{ order.name }}
            </span>
          </Copy>
        </b-table-column>
        <b-table-column #default="{ row: order }" field="shipment_type" label="Verzendmethode" sortable>
          <courier format="short" :item="order" />
        </b-table-column>
        <b-table-column #default="{ row: order }" field="country" label="Land" sortable>
          <country flag format="short" :value="order.country"></country>
        </b-table-column>
        <b-table-column #default="{ row: order }" field="order_date" label="Orderdatum" numeric sortable>
          {{
            new Intl.DateTimeFormat('nl-NL', { day: 'numeric', month: 'short', year: 'numeric' }).format(
              new Date(order.order_date || order.created_at),
            )
          }}
        </b-table-column>

        <b-table-column
          #default="{ row: order }"
          cell-class="!tw-border-l-2 tw-w-14 tw-space-x-2 !tw-border-l group-[.odd]:tw-bg-[#fafafa] group-hover:!tw-bg-[whitesmoke] tw-sticky tw-right-0 tw-z-10 tw-bg-white"
          header-class="!tw-border-l-2 tw-w-14 tw-space-x-2 !tw-border-l group-[.odd]:tw-bg-[#fafafa] group-hover:!tw-bg-[whitesmoke] tw-sticky tw-right-0 tw-z-10 !tw-bg-white"
          label="Acties"
        >
          <b-button
            aria-label="aanpassen"
            class="is-small"
            tag="router-link"
            title="Aanpassen"
            :to="{ name: 'order', params: { orderId: order.id } }"
          >
            <font-awesome-icon class="icon" fixed-width icon="pen" />
          </b-button>

          <b-dropdown append-to-body position="is-bottom-left">
            <template #trigger>
              <button aria-label="acties" class="button is-small" title="acties">
                <font-awesome-icon class="icon" fixed-width icon="ellipsis-v" />
              </button>
            </template>

            <b-dropdown-item
              aria-role="listitem"
              style="display: flex; align-items: center; gap: 8px"
              @click="duplicateOrder(order.id)"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="copy" />
              Bestelling dupliceren
            </b-dropdown-item>

            <b-dropdown-item
              aria-role="listitem"
              style="display: flex; align-items: center; gap: 8px"
              @click="deactivateOrder(order)"
            >
              <font-awesome-icon class="is-size-5" fixed-width icon="trash" />
              Bestelling verwijderen
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </template>

      <template #table-empty>
        <p class="has-text-centered block">Geen bestellingen gevonden</p>
        <banner
          v-if="(activeShops.length === 0 && !view.isLoading) || isLoading"
          bannerText="Er zijn nog geen webshops gekoppeld aan dit account. Ga naar instellingen, of druk op de onderstaande knop om ze in te stellen."
          buttonText="Koppel je eerste webshop"
          href="/settings/services/shop"
          type="is-warning"
        ></banner>
        <banner
          v-if="(activeShops.length > 0 && !view.isLoading) || isLoading"
          bannerText="Hoera, alle bestellingen zijn verwerkt"
          type="is-success"
        ></banner>
      </template>
    </base-tab>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import BaseTab from './BaseTab';
  import Banner from '@/components/Banner.vue'; // TODO kijk hiernaar
  import PrintControls from '@/components/PrintControls.vue';
  import Country from '@/components/properties/Country';
  import Courier from '@/components/properties/Courier';
  import WebShop from '@/components/properties/WebShop';
  import { awaitIfPromise, getMoment, reportLabelError, reportLabelWarning } from '@/utils/functions';
  import { AbortPrintException, ApiError } from '@/utils/errors';
  import OrderMixin from '@/components/mixins/OrderMixin';
  import OrderStatus from '@/components/properties/OrderStatus.vue';
  import Copy from '@/components/properties/Copy.vue';
  import { CONFIG_SHOULD_CHECK_FOR_PAYMENT } from '@/config';
  import TransferModal from '@/components/modals/TransferModal.vue';

  export default {
    components: { OrderStatus, Copy, Banner, BaseTab, Country, Courier, WebShop, PrintControls },
    mixins: [OrderMixin],
    props: {
      controls: {
        type: Boolean,
        default: true,
      },
      viewName: {
        type: String,
        default: 'orderTab',
      },
      orderFilter: {
        type: Object,
      },
    },
    setup() {},
    data() {
      return {
        isUpdatingLabel: false,
        isCreatingLabel: false,
        selectedOrders: [],
        formats: ['a4'],
        sort: null,
        isLoading: false,
      };
    },
    computed: {
      ...mapState({
        organisation: state => state.account.organisation,
        labelsPrintedCount: state => state.account.labelCount || 0,
        view(state) {
          return state.order.views[this.viewName];
        },
        activeShops(state) {
          // get all shops that are active
          const activeShops = state['shop'].all.filter(shop => shop.is_active);
          // sort shops by id
          return activeShops.sort((a, b) => a.id.localeCompare(b.id));
        },
      }),
      ...mapGetters({
        printerClientIsReady: 'app/printerClientIsReady',
      }),

      selectedOrderIds() {
        return this.selectedOrders.map(order => order.id);
      },

      totalLabelsToPrint() {
        return this.selectedOrders.reduce((totalLabels, order) => totalLabels + order.shipment_packages, 0);
      },

      /**
       * Check whether the user is allowed to create a label for the given order
       * Checks: Quota, Trail
       * @returns {{reason: string, allowed: boolean}|{reason: string, allowed: boolean, counter: number}|{allowed: boolean}}
       */
      //TODO Move to create label vuex action
      canCreate() {
        //initialize
        const totalLabelsToPrint = this.totalLabelsToPrint;

        // If the organisation's plan does is blocked from creating labels, return false
        if (!this.organisation?.can_create_labels) {
          return {
            allowed: false,
            reason: 'invoice',
          };
        }

        // Get the plan's label quota
        const quota = this.organisation.plan?.quota?.labels;

        // If the user has exceeded their quota, return false
        if (quota && this.labelsPrintedCount + totalLabelsToPrint > quota) {
          return {
            allowed: false,
            counter: this.labelsPrintedCount + totalLabelsToPrint,
            reason: 'quota',
          };
        }

        // If the user has not paid, return false
        if (CONFIG_SHOULD_CHECK_FOR_PAYMENT && this.organisation?.payment?.status !== 'ready') {
          return { allowed: false, reason: 'payment' };
        }

        // If anything goes wrong on our part, give user grace
        return { allowed: true };
      },
    },
    methods: {
      async duplicateOrder(orderId) {
        const { shouldTransferOrderNumber, shouldTransferShipmentSettings } = await new Promise(resolve => {
          this.$buefy.modal.open({
            component: TransferModal,
            parent: this,
            hasModalCard: true,
            props: {
              type: 'order',
              onConfirm: async toTranfer => {
                resolve(toTranfer);
              },
            },
          });
        });
        this.$router.push({
          name: 'order',
          params: { orderId },
          query: {
            duplicate: true,
            transferOrderNumber: shouldTransferOrderNumber,
            transferShipmentSettings: shouldTransferShipmentSettings,
          },
        });
      },
      getTicket(label) {
        return label.ticket ? Object.keys(label.ticket).length > 0 : null;
      },
      updateView() {
        this.$store.dispatch('order/updateView', { view: this.viewName });
      },
      loadView(page, filter) {
        this.$store.dispatch('order/loadView', {
          view: this.viewName,
          filter: {
            ...filter,
            ...this.orderFilter,
            has_shipments: false,

            page,
          },
        });
      },

      async resetOrders() {
        this.isLoading = true;
        this.$buefy.dialog.confirm({
          title: 'Verzendregels opnieuw toepassen?',
          message: `Weet je zeker dat je de verzendregels opnieuw wilt toepassen?`,
          cancelText: 'Annuleren',
          confirmText: 'Resetten',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            await this.$store.dispatch('order/resetOrders', this.selectedOrderIds);
            this.isLoading = false;
            this.selectedOrders = [];
            await this.$store.dispatch('order/reloadViews', { silent: false });
            this.$buefy.toast.open('Orders gereset!');
          },
          onCancel: () => {
            this.isLoading = false;
          },
        });
      },
      async update() {
        this.isUpdatingLabel = true;
        try {
          await this.$store.dispatch('order/update');
        } finally {
          this.isUpdatingLabel = false;
        }
      },
      async createLabels(callback, force = false) {
        let result = {};

        this.isCreatingLabel = true;
        try {
          result = await this.$store.dispatch('label/createLabels', { orderIds: this.selectedOrderIds, force });

          if (result.errors.length > 0) reportLabelError(result);

          const labelIds = this.selectedOrderIds
            .map(id => result.data.find(label => label.order_id === id)?.id)
            .filter(label => !!label);

          return await awaitIfPromise(callback({ labelIds }));
        } catch (error) {
          if (error instanceof ApiError && error.response.status === 'warning') {
            reportLabelWarning(error.response, {
              cancelText: 'Annuleren',
              confirmText: 'Labels aanmaken',
              onConfirm: async () => {
                try {
                  await this.createLabels(callback, true);
                } catch (error) {
                  if (!(error instanceof AbortPrintException)) {
                    // An actual error occurred, rethrow for logging
                    throw error;
                  }
                }
              },
            });
          } else if (error instanceof ApiError) {
            reportLabelError(error.response);
          } else {
            throw error;
          }
        } finally {
          // TODO: Maybe move to store/label/createLabels
          await this.$store.dispatch('account/getLabelCount');

          // Update orders
          if (Object.keys(result).length > 0) {
            await this.$store.dispatch('order/reloadViews', { silent: false }, { root: true });
            await this.$store.dispatch(
              'order/addShipmentsToOrdersAction',
              result?.data?.map(l => ({ order_id: l.order_id, shipment_id: l.shipment_id, label_id: l.id })),
              { root: true },
            );
          }

          this.isCreatingLabel = false;
        }
      },
      confirmBillingReport() {
        this.$router.push({ name: 'collection' });
      },
      deactivateBulkOrders() {
        const orderNumbers = this.selectedOrders.map(i => i.order_number);

        this.$buefy.dialog.confirm({
          title: 'Order verwijderen?',
          message: `Weet je zeker dat je de orders: ${orderNumbers} wil verwijderen? Dit kan niet ongedaan worden`,
          cancelText: 'Annuleren',
          confirmText: 'Verwijderen',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            this.isLoading = true;
            await this.$store.dispatch('order/updateBulkOrders', {
              order_ids: this.selectedOrderIds,
              action: 'deactivate',
            });
            this.isLoading = false;
            this.selectedOrders = [];

            await this.$store.dispatch('order/reloadViews', { silent: false });
            this.$buefy.toast.open('Orders verwijderd!');
          },
        });
      },
      deactivateOrder(order) {
        this.$buefy.dialog.confirm({
          title: 'Order verwijderen?',
          message: `Weet je zeker dat je deze order (ordernummer ${order.order_number}) wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
          cancelText: 'Annuleren',
          confirmText: 'Verwijderen',
          type: 'is-danger',
          hasIcon: true,

          onConfirm: async () => {
            this.isLoading = true;

            await this.$store.dispatch('order/updateOrder', {
              id: order.id,
              is_active: false,
            });

            this.isLoading = false;

            await this.$store.dispatch('order/reloadViews', { silent: false });
            this.$buefy.toast.open('Order verwijderd!');
          },
        });
      },
      getOrderTimestamp(order) {
        if (order.order_date) return getMoment(order.order_date);
        return getMoment(order.created_at);
      },
    },
  };
</script>
