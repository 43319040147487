<script setup>
  import { inject, ref } from 'vue';
  import { useContact } from '@/composables/contact/useContact';
  import { useContactSearch } from '@/composables/contact/useContactSearch';

  const activeSelectedContact = inject('activeSelectedContact');
  const selectedAddress = ref(activeSelectedContact.value || {});

  const { initContact } = useContact();
  const { filteredContacts, searchQuery, isLoading } = useContactSearch();

  const emit = defineEmits(['close']);
  const props = defineProps({
    onSelect: Function,
  });

  const handleSubmit = () => {
    emit('select', selectedAddress.value);
    emit('close');
  };

  initContact();
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div class="modal-card tw-w-auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Adresboek</p>
        <button class="delete" type="button" @click="$emit('close')" />
      </header>
      <section class="modal-card-body">
        <b-field>
          <b-input v-model="searchQuery" expanded placeholder="Zoeken..." type="search" />
          <p class="control">
            <b-button class="tw-p-4 is-static">
              <font-awesome-icon v-if="isLoading" class="tw-animate-spin" icon="spinner" />
              <font-awesome-icon v-else icon="search" />
            </b-button>
          </p>
        </b-field>

        <ul class="tw-w-96 tw-flex tw-flex-col tw-gap-4">
          <li
            v-for="contact in filteredContacts"
            :key="contact.id"
            :class="[
              'tw-flex tw-justify-between tw-border tw-border-primary tw-border-solid tw-rounded-lg tw-items-start tw-px-4 tw-py-2',
              'tw-cursor-pointer tw-transition-colors',
              { 'hover:tw-bg-primary/80': selectedAddress.id !== contact.id },
              { 'tw-bg-primary tw-text-white': selectedAddress.id === contact.id },
            ]"
            @click="selectedAddress = contact"
          >
            <div>
              <p class="tw-font-semibold">{{ contact.name }}</p>
              <p class="tw-text-sm">
                {{ [contact.address_1, contact.address_2].filter(Boolean).join(', ') }}
              </p>
              <p class="tw-text-sm">
                {{ [contact.city, contact.zipcode].filter(Boolean).join(', ') }}
              </p>
            </div>
            <div class="tw-text-right">
              <p class="tw-text-sm">{{ contact.company_name }}</p>
              <p class="tw-text-sm">{{ contact.phone }}</p>
            </div>
          </li>
        </ul>
      </section>
      <footer class="modal-card-foot tw-justify-between">
        <b-button label="Sluiten" native-type="button" @click="$emit('close')" />
        <b-button class="is-primary" :disabled="!selectedAddress" label="Bevestigen" native-type="submit" />
      </footer>
    </div>
  </form>
</template>
