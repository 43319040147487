<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Order bevat meerdere labels</p>
      <button class="delete" type="button" @click="cancel">
        <span class="tw-sr-only"> Sluiten </span>
      </button>
    </header>
    <section class="modal-card-body">
      <b-field class="tw-min-h-64" label="Selecteer de labels die je wilt printen/downloaden">
        <b-taginput
          ref="tagInput"
          v-model="selectedLabels"
          :allow-new="false"
          append-to-body
          autocomplete
          :data="filteredAutoCompleteData"
          field="trackingNumber"
          icon="label"
          open-on-focus
          @typing="getFilteredLabels"
        >
          <template #tag="{ tag }">
            <div class="tw-flex tw-items-center tw-gap-2">
              {{ tag.trackingNumber }}
              <span v-if="tag.is_returned" class="tag !tw-h-auto tw-py-1 tw-px-2">RETOUR</span>
            </div>
          </template>
          <template #default="{ option }">
            <div class="tw-flex tw-items-center tw-gap-2">
              {{ option.trackingNumber }}
              <span v-if="option.is_returned" class="tag !tw-h-auto tw-py-1 tw-px-2">RETOUR</span>
            </div>
          </template>
        </b-taginput>
      </b-field>
      <div class="tw-flex tw-gap-2 tw-items-center">
        <button class="button" type="button" @click="selectedLabels = filteredAutoCompleteData">
          Alles selecteren
        </button>
        <button class="button is-warning" type="button" @click="selectedLabels = []">Alles deselecteren</button>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" @click="confirm">
        <span v-if="preferences.label_settings.mode === 'printer'">Printen</span>
        <span v-else>Downloaden</span>
      </button>
      <button class="button" @click="cancel">Annuleren</button>
    </footer>
  </div>
</template>

<script>
  import { api } from '@/utils';
  import PreferencesMixin from '@/components/mixins/PreferencesMixin';

  export default {
    mixins: [PreferencesMixin],
    props: {
      labelIds: {
        type: Array,
        required: true,
      },
      onCancel: {
        type: Function,
        required: true,
      },
      onConfirm: {
        type: Function,
        required: true,
      },
    },
    async mounted() {
      this.$refs.tagInput.focus();
      const labels = [];
      for (const labelId of this.labelIds) {
        const result = await api.getLabel(labelId);

        labels.push({
          id: labelId,
          trackingNumber: result.data.shipment.packages.map(p => p.tracking_number).join(', '),
          is_returned: result.data.is_return,
        });

        this.autoCompleteData = labels;
        this.filteredAutoCompleteData = labels;
      }
    },
    data() {
      return {
        autoCompleteData: [],
        selectedLabels: [],
        filteredAutoCompleteData: [],
      };
    },
    watch: {
      selectedLabels() {
        this.getFilteredLabels('');
      },
    },
    methods: {
      getFilteredLabels(query) {
        this.filteredAutoCompleteData = this.autoCompleteData.filter(
          // filter out based on query and selected labels, use label.id to match
          label => label.trackingNumber.includes(query) && !this.selectedLabels.map(l => l.id).includes(label.id),
        );
      },
      cancel() {
        this.onCancel();
        this.$parent.close();
      },
      confirm() {
        this.onConfirm(this.selectedLabels.map(label => label.id));
        this.$parent.close();
      },
    },
  };
</script>
