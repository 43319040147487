import { ref, watch } from 'vue';

const DEFAULT_SETTINGS = {
  label_settings: {
    mode: 'pdf',
    format: 'a6',
  },
};

export function usePreferences() {
  const preferences = ref(JSON.parse(localStorage.getItem('preferences')) || DEFAULT_SETTINGS);

  watch(preferences, newValue => localStorage.setItem('preferences', JSON.stringify(newValue)), { deep: true });

  return { preferences };
}
