import { ref } from 'vue';

export function useAddressCompletion() {
  const isLoading = ref(false);
  const error = ref(null);

  const completeAddress = async (zipcode, housenumber) => {
    error.value = null;
    isLoading.value = true;

    try {
      if (!zipcode?.trim() || !housenumber?.trim()) {
        return { street: '', city: '' };
      }

      const cleanZipcode = zipcode.replace(/\s+/g, ''); // Remove whitespace
      const cleanHousenumber = housenumber.replace(/\s+/g, ''); // Remove whitespace
      const query = `postcode:"${cleanZipcode}" and huis_nlt:"${cleanHousenumber}" and type:adres`;
      const queryFields = 'fl=straatnaam,woonplaatsnaam,type';
      const url = `https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?rows=1&${queryFields}&q=${encodeURI(query)}`;

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Failed to fetch address data');
      }

      const data = await response.json();
      const doc = data?.response?.docs?.[0];

      if (data?.response?.numFound === 1 && doc?.type === 'adres') {
        return { street: doc.straatnaam, city: doc.woonplaatsnaam };
      }

      return { street: '', city: '' };
    } catch (err) {
      error.value = err.message;
      return { street: '', city: '' };
    } finally {
      isLoading.value = false;
    }
  };

  return {
    isLoading,
    error,
    completeAddress,
  };
}
