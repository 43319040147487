<template>
  <li>
    <div
      class="tw-border tw-border-solid tw-rounded-2xl tw-py-2 tw-px-4 tw-space-y-4"
      :class="{
        'tw-border-sky-600': ['order_created', 'ready', 'printed'].includes(entry?.status),
        'tw-border-primary': ['completed', 'shipped'].includes(
          entry && TRACKING_STATUS_MAPPING[entry.status]?.label_status,
        ),
        'tw-border-amber-500': ['warning', 'delayed'].includes(
          entry && TRACKING_STATUS_MAPPING[entry.status]?.label_status,
        ),
      }"
    >
      <header class="tw-flex tw-items-center tw-justify-between tw-gap-2">
        <span class="tw-gap-2 tw-flex tw-items-center">
          <font-awesome-icon v-if="icon" fixed-width :icon="icon" />

          {{ tag }}
        </span>
        <span v-if="!hideTimestamp" class="tw-text-neutral-500 tw-text-xs">
          <slot name="timestamp">{{ formatTime(entry?.first_seen_at) }}</slot>
        </span>
      </header>

      <div v-if="$slots.content">
        <slot name="content" />
      </div>

      <footer v-if="hasFooter">
        <b-modal :active="isShowingMap" @close="toggleMap">
          <div class="card tw-flex tw-flex-col tw-p-4">
            <div class="card-header tw-flex tw-justify-between tw-items-start">
              <address class="tw-not-italic tw-flex-col tw-flex tw-items-start">
                <h1 class="tw-font-bold tw-text-lg tw-flex tw-gap-2 tw-items-center">
                  <b-tooltip v-if="flags[entry.location?.country?.toUpperCase()]" append-to-body type="is-dark">
                    <div
                      class="tw-h-6 tw-w-6 tw-flex tw-items-center"
                      v-html="flags[entry.location?.country?.toUpperCase()]"
                    />
                    <template #content>
                      {{ Country.getCountryByCode(entry.location?.country)?.name || entry.location?.country }}
                    </template>
                  </b-tooltip>
                  <span v-else>
                    {{ entry.location?.country }}
                  </span>
                  <span v-if="entry.location?.country && entry.location?.name">|</span>
                  {{ entry.location?.name }}
                </h1>
                <p v-if="hasSufficientLocationData">
                  <span>{{ entry.location?.address_1 }}</span
                  ><span v-if="entry.location?.address_2">, {{ entry.location?.address_2 }}</span>
                  <span v-if="entry.location?.address_1 || entry.location?.address_2"> | </span>
                  <span>{{ entry.location?.zipcode }} {{ entry.location?.city }}</span>
                </p>
                <p v-else>
                  <span>{{
                    Object.values(entry.location || {})
                      .filter(Boolean)
                      .join(', ')
                  }}</span>
                </p>
              </address>
              <button class="delete" @click="toggleMap" />
            </div>
            <div class="tw-p-4">
              <iframe
                allowfullscreen
                aria-describedby="address-info"
                class="tw-p-2 tw-h-[60dvh] tw-rounded-2xl tw-bg-primary/50 tw-w-full"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyCOBfmoRfFG-xWkw4XGtcEVaUerTAyyK68&q=${Object.values(
                  entry.location || {},
                )
                  .filter(Boolean)
                  .join(',')}`"
                style="border: 0"
              >
              </iframe>
              <a
                class="tw-underline tw-block tw-text-balance tw-text-center"
                :href="`https://www.google.com/maps/search/?api=1&query=${Object.values(entry.location || {})
                  .filter(Boolean)
                  .join(' ')}`"
                rel="noopener noreferrer"
                target="_blank"
              >
                Als de kaart niet wordt weergegeven, klik hier om te openen in Google Maps
              </a>
            </div>
          </div>
        </b-modal>

        <div class="tw-flex tw-gap-2 tw-items-end tw-justify-end">
          <b-tooltip v-if="hasLocation" append-to-body type="is-dark">
            <button
              class="tw-bg-neutral-200 hover:tw-bg-primary hover:tw-text-primary-foreground tw-cursor-pointer tw-p-1 tw-flex tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-rounded-full"
              title="Bekijk op de kaart"
              @click="toggleMap"
            >
              <font-awesome-icon
                aria-describedby="address-info"
                class="tw-text-base"
                fixed-width
                icon="map-marker-alt"
              />
              <span class="tw-sr-only">Bekijk op de kaart</span>
            </button>
            <template #content>
              <address v-if="hasSufficientLocationData" id="address-info" class="tw-not-italic">
                <span>{{ entry.location?.address_1 }}</span
                ><span v-if="entry.location?.address_2">, {{ entry.location?.address_2 }}</span
                ><br />
                <span>{{ entry.location?.zipcode }} {{ entry.location?.city }}</span
                ><br />
                <span>{{ Country.getCountryByCode(entry.location?.country).name || entry.location?.country }}</span>
              </address>
              <address v-else id="address-info" class="tw-not-italic">
                <span>{{
                  Object.values(entry.location || {})
                    .filter(Boolean)
                    .join(', ')
                }}</span>
              </address>
            </template>
          </b-tooltip>

          <div v-if="hasCourierDescription" class="tw-flex tw-gap-2 tw-items-center">
            <b-tooltip v-if="entry.courier_description" type="is-dark">
              <div
                class="tw-bg-neutral-200 tw-p-1 tw-flex tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-rounded-full"
              >
                <span class="tw-sr-only">Vervoerder Status</span>
                <font-awesome-icon
                  aria-describedby="address-info"
                  class="tw-w-full"
                  :class="{
                    'tw-text-primary': ['completed', 'shipped'].includes(
                      TRACKING_STATUS_MAPPING[entry.status].label_status,
                    ),
                    'tw-text-amber-500': ['warning', 'delayed'].includes(
                      TRACKING_STATUS_MAPPING[entry.status].label_status,
                    ),
                    'tw-text-sky-600': ['order_created', 'ready', 'printed'].includes(entry.status),
                  }"
                  fixed-width
                  icon="truck-front"
                />
              </div>
              <template #content>
                {{ entry.courier_description }}
              </template>
            </b-tooltip>
          </div>
        </div>
      </footer>
    </div>
  </li>
</template>
<script setup>
  import { computed, ref } from 'vue';
  import { TRACKING_STATUS_MAPPING } from '@/constants/COMMON';
  import { Country } from 'country-state-city';
  import moment from 'moment';
  import * as flags from 'country-flag-icons/string/3x2';

  const {
    entry,
    icon: propIcon,
    tag: propTag,
    hideTimestamp = true,
  } = defineProps({
    entry: {
      type: Object,
      default: () => ({}),
    },
    icon: {
      type: Object,
      default: null,
    },
    tag: {
      type: String,
      default: null,
    },
    hideTimestamp: {
      type: Boolean,
      default: false,
    },
  });

  // For debugging purposes
  // Injecting fake data into location for demo purposes
  // entry.location = {
  //   name: 'Demo Location',
  //   address_1: '123 Demo Street',
  //   address_2: 'Suite 456',
  //   housenumber: '789',
  //   zipcode: '12345',
  //   city: 'Demo City',
  //   country: 'GB',
  //   latitude: '52.3676',
  //   longitude: '4.9041',
  // };

  const icon = computed(() => propIcon || TRACKING_STATUS_MAPPING[entry?.status]?.icon);
  const tag = computed(() => propTag || TRACKING_STATUS_MAPPING[entry?.status]?.tag);

  const formatTime = datetime => {
    return moment(datetime).locale('nl').calendar();
  };

  const isShowingMap = ref(false);

  const toggleMap = () => {
    isShowingMap.value = !isShowingMap.value;
  };

  const hasLocation = computed(() => {
    return Object.keys(entry.location || {}).length > 0;
  });

  const hasCourierDescription = computed(() => !!entry.courier_description);

  const hasFooter = computed(() => hasLocation.value || hasCourierDescription.value);

  // Check if the location data is sufficient to display
  // If not, we will display the location data as a single string joined by commas
  const hasSufficientLocationData = computed(() => {
    return !!(entry.location?.address_1 && entry.location?.zipcode && entry.location?.city && entry.location?.country);
  });
</script>
