<template>
  <b-tooltip
    :active="!!tooltip"
    append-to-body
    multilined
    :type="
      cn({
        'is-danger': isError,
        'is-warning': isWarning,
        'is-success': isSuccess,
        'is-dark': !isError && !isWarning && !isSuccess,
      })
    "
  >
    <template #default>
      <span
        class="tag"
        :class="{
          'tw-cursor-help': !!tooltip,
          'is-danger': isError,
          'is-warning': isWarning,
          'is-success': isSuccess,
        }"
      >
        {{ text }}
      </span>
    </template>
    <template #content>
      {{ tooltip }}
    </template>
  </b-tooltip>
</template>

<script setup>
  import { STATUS_MAPPING } from '@/constants/COMMON';
  import { computed } from 'vue';
  import { cn } from '@/utils/functions';

  const props = defineProps({
    label: Object,
  });

  const ERROR_STATUSES = ['error'];
  const WARNING_STATUSES = [];
  const SUCCESS_STATUSES = ['ready', 'printed', 'completed'];

  const rawErrorStatus = computed(() => props.label?.error_status || props.label?.status);
  const isError = computed(() => props.label?.error_status || ERROR_STATUSES.includes(rawErrorStatus.value));
  const isWarning = computed(() => WARNING_STATUSES.includes(rawErrorStatus.value));
  const isSuccess = computed(() => SUCCESS_STATUSES.includes(rawErrorStatus.value));

  const text = computed(() => STATUS_MAPPING['label'][props.label?.status]?.tag);
  const tooltip = computed(() => STATUS_MAPPING['label'][props.label?.status]?.tooltip);
</script>
