import { getCurrentInstance, ref, set, watch } from 'vue';
import { useOrderCourier } from '@/composables/order/useOrderCourier';

const STATUS_TYPE_MAP = {
  error: 'is-danger',
  warning: 'is-warning',
  info: 'is-info',
};

const STATUS_PRIORITY = {
  'is-danger': 3,
  'is-warning': 2,
  'is-info': 1,
};

/**
 * @typedef {object} ErrorObject
 * @property {string[]} messages - An array of error messages.
 * @property {string} type - The type of error (e.g., 'error', 'warning', 'info').
 */

/**
 * Custom hook for managing order errors.
 *
 * @param {object} order - The order object.
 * @returns {{errors: object, setError: Function, getError: Function}} - An object containing the errors, setError function, and getError function.
 */
export const useOrderErrors = order => {
  const { selectedCourier } = useOrderCourier(order);

  const instance = getCurrentInstance();

  const errors = ref({});

  /**
   * Sets an error message for a specific field in the order.
   * The path uses dot notation to target nested fields within the order object.
   * The error object contains the error message and the type of error (e.g., 'error', 'warning', 'info').
   * If an error already exists for the given path, the new message is added to the existing messages array,
   * and the error type is updated if the new type has a higher priority.
   *
   * @param {string} path - The path to the field in the order object (e.g., 'customer.name', 'shipment.packages[0].weight').
   *                        Uses dot notation to access nested fields.
   * @param {object} error - An object containing the error message and type.
   * @param {string|string[]} error.message - The error message or an array of error messages.  Supports both single messages and multiple messages.
   * @param {string} error.type - The type of error (e.g., 'error', 'warning', 'info').  Corresponds to Bulma CSS classes (is-danger, is-warning, is-info).
   *
   * @example
   * setError('customer.name', { message: 'Name is required', type: 'error' });
   * setError('shipment.packages[0].weight', { message: ['Weight is required', 'Weight must be a number'], type: 'error' });
   */
  const setError = (path, { message, type }) => {
    const parts = path.split('.');
    let current = errors.value;
    let keyToUpdate = null;
    let parent = null;

    for (const part of parts) {
      parent = current;
      keyToUpdate = part;

      if (!current[part]) {
        set(current, part, {});
      }
      current = current[part];
    }

    const messages = Array.isArray(message) ? message : [message];
    const newError = {
      messages: current.messages ? [...current.messages, ...messages] : messages,
      type: STATUS_PRIORITY[type] > STATUS_PRIORITY[current.type] ? type : current.type || type,
    };

    // Use Vue.set to update the error object
    set(parent, keyToUpdate, newError);
  };

  /**
   * Gets the error object for a specific field in the order.
   * Traverses the `errors` object using the provided path. If any part of the path does not exist,
   * it returns `null`.  If the full path exists, it returns the error object for that field.
   *
   * @param {string} path - The path to the field in the order object (e.g., 'customer.name').
   *                        Uses dot notation to access nested fields.
   * @returns {ErrorObject|null} - The error object if it exists, otherwise null.  The error object contains `messages` (array of strings) and `type` (string).
   *
   * @example
   * const nameError = getError('customer.name');
   * if (nameError) {
   *   console.log(nameError.messages); // ['Name is required']
   *   console.log(nameError.type); // 'error'
   * }
   */
  const getError = path => {
    const parts = path.split('.');
    let current = errors;

    for (const part of parts) {
      if (!current[part]) return null;
      current.value = current[part];
    }

    return current;
  };

  watch(
    () => [instance.proxy.$route.params.orderId, order.value.status_info],
    () => {
      if (!order.value.status_info) return;

      /** Clear all errors */
      errors.value = {};

      order.value.status_info.forEach(info => {
        if (info.field)
          setError(info.field, {
            message: info.message,
            type: STATUS_TYPE_MAP[info.status] || 'is-info',
          });
      });

      if (order.value.shipment.courier_id && !selectedCourier.value?.is_active) {
        setError('shipment.courier_id', {
          message: 'Kies een vervoerder die niet is verwijderd.',
          type: 'is-danger',
        });
      }
    },
    { deep: true, immediate: true },
  );

  const handleFormValidationErrors = payload => {
    errors.value = {};
    for (const field in payload.fields) {
      setError(field, {
        message: payload.fields[field],
        type: STATUS_TYPE_MAP[payload.status] || 'is-info',
      });
    }
  };

  return {
    errors,
    setError,
    getError,
    handleFormValidationErrors,
  };
};
