<script setup>
  import CountryCodeAutocomplete from '@/components/form/CountryCodeAutocomplete.vue';
  import StateProvinceCodeAutoComplete from '@/components/form/StateProvinceCodeAutoComplete.vue';
  import ContactsSelectModal from '@/components/modals/ContactsSelectModal.vue';
  import { computed, getCurrentInstance, inject, provide, ref } from 'vue';
  import { useContactSearch } from '@/composables/contact/useContactSearch';
  import { useContact } from '@/composables/contact/useContact';
  import { Contact } from '@/classes/Contact';
  import { pick } from '@/utils/functions';
  import { useAddressCompletion } from '@/composables/useAddressCompletion';

  const RECEIVER_FIELDS = [
    'name',
    'company_name',
    'country',
    'state_province_code',
    'zipcode',
    'housenumber',
    'address_1',
    'address_2',
    'city',
    'email',
    'phone',
  ];

  const instance = getCurrentInstance();
  const buefy = instance.proxy.$buefy;

  const order = inject('order');

  const isFormComplete = computed(() => {
    return Boolean(order.value.name && order.value.zipcode && order.value.address_1);
  });

  const { filteredContacts, isLoading, searchQuery } = useContactSearch();
  const { createContact, initContact, updateContact } = useContact();

  const errors = inject('errors');

  const activeSelectedContact = ref(null);
  provide('activeSelectedContact', activeSelectedContact);

  const openAddressBook = () => {
    buefy.modal.open({
      parent: instance.proxy,
      trapFocus: true,
      hasModalCard: true,
      component: ContactsSelectModal,

      events: {
        select: contact => {
          activeSelectedContact.value = contact;
          order.value = Object.assign({}, order.value, Contact.getFieldsFrom(contact));
        },
      },
    });
  };

  const onContactSelect = contact => {
    if (contact) {
      order.value = Object.assign({}, order.value, Contact.getFieldsFrom(contact));
    }
    activeSelectedContact.value = contact;
  };

  const onUpdateContact = contact => {
    if (activeSelectedContact.value.id === contact.id) {
      order.value = Object.assign({}, order.value, Contact.getFieldsFrom(contact));
    }
  };

  const handleContactCreation = () => {
    createContact({
      form: pick(order.value, RECEIVER_FIELDS),
      callback: contact => (activeSelectedContact.value = contact),
    });
  };

  const { completeAddress, error: addressCompletionError } = useAddressCompletion();

  const triggerAddressCompletion = async () => {
    if (!order.value.zipcode || !order.value.housenumber) return;
    const { street, city } = await completeAddress(order.value.zipcode, order.value.housenumber);

    if (!addressCompletionError.value) {
      if (street) order.value.address_1 = street;
      if (city) order.value.city = city;
    }
    console.error(addressCompletionError);
  };
</script>
<template>
  <div>
    <b-field label="Naam" :message="errors.name?.messages?.join(' & ')" :type="errors.name?.type">
      <b-autocomplete
        v-model="order.name"
        autocomplete="off"
        clearable
        :data="filteredContacts"
        expanded
        field="name"
        keep-first
        open-on-focus
        @focus="initContact"
        @select="onContactSelect"
        @typing="searchQuery = $event"
      >
        <template #empty> Geen contacten gevonden </template>
        <template #default="{ option: contact }">
          <h1 class="tw-font-semibold">{{ contact.name }}</h1>
          <div class="tw-flex tw-justify-between">
            <div>
              <p class="tw-text-sm">
                {{ [contact.address_1, contact.address_2].filter(Boolean).join(', ') }}
              </p>
              <p class="tw-text-sm">
                {{ [contact.city, contact.zipcode].filter(Boolean).join(', ') }}
              </p>
              <a @click.stop="updateContact({ contact, callback: onUpdateContact })">Bewerken</a>
            </div>
            <div class="tw-text-right">
              <p class="tw-text-sm">{{ contact.company_name }}</p>
              <p class="tw-text-sm">{{ contact.phone }}</p>
            </div>
          </div>
        </template>

        <template #header>
          <a @click="createContact({ callback: contact => Object.assign(order, contact) })"> Nieuw contact aanmaken </a>
        </template>
      </b-autocomplete>
      <p class="control">
        <b-button class="tw-p-4" @click="openAddressBook">
          <font-awesome-icon v-if="isLoading" class="tw-animate-spin" icon="spinner" />
          <font-awesome-icon v-else icon="address-book" />
        </b-button>
      </p>
    </b-field>
    <b-field
      label="Bedrijfsnaam"
      :message="errors.company_name?.messages?.join(' & ')"
      :type="errors.company_name?.type"
    >
      <b-input v-model="order.company_name" autocomplete="off" />
    </b-field>
    <b-field label="Land" :message="errors.country?.messages?.join(' & ')" :type="errors.country?.type">
      <country-code-autocomplete v-model="order.country" autocomplete="off" />
    </b-field>
    <b-field :message="errors.state_province_code?.messages?.join(' & ')" :type="errors.state_province_code?.type">
      <template #label>
        <span class="tw-inline-flex tw-items-center tw-gap-2">
          <span>Staat / Provincie</span>
          <b-tooltip multilined type="is-dark">
            <font-awesome-icon class="tw-h-4 tw-w-4" icon="info-circle" />
            <template #content>
              Staat / Provincie enkel benodigt bij landen zoals de Verenigde Staten, Canada en China.
            </template>
          </b-tooltip>
        </span>
      </template>
      <state-province-code-auto-complete
        v-model="order.state_province_code"
        :country="order.country"
      ></state-province-code-auto-complete>
    </b-field>
    <b-field label="Postcode" :message="errors.zipcode?.messages?.join(' & ')" :type="errors.zipcode?.type">
      <b-input v-model="order.zipcode" autocomplete="off" @blur="triggerAddressCompletion" />
    </b-field>
    <b-field
      label="Huisnummer en toevoeging"
      :message="errors.housenumber?.messages?.join(' & ')"
      :type="errors.housenumber?.type"
    >
      <b-input v-model="order.housenumber" autocomplete="off" @blur="triggerAddressCompletion" />
    </b-field>
    <b-field label="Adres regel 1" :message="errors.address_1?.messages?.join(' & ')" :type="errors.address_1?.type">
      <b-input v-model="order.address_1" autocomplete="off" />
    </b-field>
    <b-field label="Adres regel 2" :message="errors.address_2?.messages?.join(' & ')" :type="errors.address_2?.type">
      <b-input v-model="order.address_2" autocomplete="off" />
    </b-field>
    <b-field label="Plaats" :message="errors.city?.messages?.join(' & ')" :type="errors.city?.type">
      <b-input v-model="order.city" autocomplete="off" />
    </b-field>
    <b-field label="E-mail" :message="errors.email?.messages?.join(' & ')" :type="errors.email?.type">
      <b-input v-model="order.email" autocomplete="off" type="email" />
    </b-field>
    <b-field label="Telefoonnummer" :message="errors.phone?.messages?.join(' & ')" :type="errors.phone?.type">
      <b-input v-model="order.phone" autocomplete="off" />
    </b-field>

    <b-tooltip :active="!isFormComplete" multilined type="is-dark">
      <template #content>
        Je moet op zijn minst een naam, postcode en adresregel 1 invullen om een contact op te slaan.
      </template>
      <b-button
        v-if="!activeSelectedContact"
        class="tw-mt-4"
        :disabled="!isFormComplete"
        @click="handleContactCreation"
      >
        <span class="tw-flex tw-items-center tw-gap-1">
          <font-awesome-icon icon="save" />
          <span> Opslaan als contact </span>
        </span>
      </b-button>
    </b-tooltip>
  </div>
</template>
