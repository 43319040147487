import { computed, getCurrentInstance, ref, watch } from 'vue';
import { useContact } from '@/composables/contact/useContact';
import { debounce } from '@/utils/functions';

export const useContactSearch = () => {
  const instance = getCurrentInstance();
  const store = instance.proxy.$store;

  const { contacts } = useContact();

  const searchQuery = ref('');
  const isLoading = ref(false);
  let timeoutId = null;

  const debouncedSearch = debounce(
    async query => {
      isLoading.value = true;
      try {
        await store.dispatch('contact/search', query);
      } finally {
        isLoading.value = false;
      }
    },
    500,
    timeoutId,
  );

  watch(searchQuery, newQuery => {
    if (newQuery) {
      timeoutId = debouncedSearch(newQuery);
      isLoading.value = true;
    } else {
      clearTimeout(timeoutId);
      isLoading.value = false;
    }
  });

  const filteredContacts = computed(() => {
    if (!searchQuery.value) {
      return contacts.value;
    }

    const searchTerm = searchQuery.value.toLowerCase();
    return contacts.value.filter(contact => JSON.stringify(contact).toLowerCase().includes(searchTerm));
  });

  return {
    searchQuery,
    isLoading,
    filteredContacts,
  };
};
