import { computed, getCurrentInstance } from 'vue';

/**
 * Composable for managing order courier information and initialization.
 *
 * @param {object} order - The order object containing shipment details.
 */
export const useOrderCourier = order => {
  const instance = getCurrentInstance();
  const store = instance.proxy.$store;

  /**
   * A list of all active couriers.
   */
  const activeCouriers = computed(() =>
    store.state.courier.all.filter(o => o.is_active).sort((a, b) => a.id.localeCompare(b.id)),
  );

  /**
   * A list of all available couriers, including any couriers that might be inactive but are still assigned to the order.value.
   */
  const filteredCouriers = computed(() =>
    store.state.courier.all.filter(
      courier =>
        courier.is_active ||
        courier.id === order.value.shipment.value.courier_id ||
        courier.proxied_id === order.value.shipment.value.courier_id,
    ),
  );

  /**
   * The shipment types available for the selected courier.
   */
  const shipmentTypes = computed(() => {
    return (
      filteredCouriers.value.find(courier => courier.id === order.value?.shipment?.courier_id)?.shipment_types || []
    );
  });

  /**
   * The selected courier object.
   */
  const selectedCourier = computed(() => store.getters['courier/courierById'](order.value.shipment.courier_id));

  /**
   * Meta information for the selected courier.
   */
  const selectedCourierMeta = computed(() => store.getters['courier/metaById'](order.value.shipment.courier_id));

  /**
   * Initializes the courier for the order, setting default values and handling inactive couriers.
   * @function initCourierForOrder
   */
  const initCourierForOrder = () => {
    // Set default courier if none selected
    if (!order.value.shipment.courier_id && activeCouriers.value.length) {
      order.value.shipment.courier_id = activeCouriers.value[0].id;
    }

    // Set default shipment type
    order.value.shipment.shipment_type =
      selectedCourierMeta.value.shipment_types.find(t => t.is_default)?.id ??
      selectedCourierMeta.value.shipment_types[0]?.id ??
      null;

    // Reset and initialize courier fields
    order.value.shipment.courier_fields = {};
    selectedCourierMeta.value.order_fields?.forEach(field => {
      if (field.default !== undefined) {
        order.value.shipment.courier_fields[field.name] = field.default;
      }
    });
  };

  /**
   * The default shipment type ID of the selected courier.
   */
  const defaultShipmentTypeOfSelectedCourier = computed(() => {
    return shipmentTypes.value.find(type => type.is_default)?.id || shipmentTypes.value[0]?.id || null;
  });

  return {
    activeCouriers,
    defaultShipmentTypeOfSelectedCourier,
    filteredCouriers,
    shipmentTypes,
    selectedCourier,
    selectedCourierMeta,
    initCourierForOrder,
  };
};
