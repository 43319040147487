<script setup>
  import { computed, inject } from 'vue';

  const order = inject('order');
  const hasMoreThanOnePackage = computed(() => order.value.shipment.packages.length > 1);

  const MAX_WEIGHT_ADDITIONAL_COST = 23000;

  const selectedCourierMeta = inject('selectedCourierMeta');

  const removePackage = index => {
    order.value.shipment.packages.splice(index, 1);
  };

  const addPackage = () => {
    order.value.shipment.packages.push({ weight: 0 });
  };

  const shouldShowWeightNotification = computed(() => {
    const isFedex = selectedCourierMeta.value?.service_class === 'Fedex';

    const totalWeight = order.value.shipment.packages.reduce((sum, p) => sum + p.weight, 0);
    const hasOverWeightPackages = totalWeight > 30000;

    return isFedex && hasOverWeightPackages;
  });
</script>
<template>
  <div>
    <b-field v-for="(_, index) in order.shipment.packages" :key="`package-${index}`">
      <p v-if="hasMoreThanOnePackage" class="control">
        <span class="button is-static tw-inline-flex tw-gap-1">
          <span class="hidden md:inline">Pakket</span>
          <span>{{ index + 1 }}</span>
        </span>
      </p>
      <b-tooltip
        v-if="order.shipment.packages[index].weight > MAX_WEIGHT_ADDITIONAL_COST"
        active
        class="control"
        multilined
        type="is-warning"
      >
        <p>
          <span class="button is-static">
            <font-awesome-icon class="has-text-warning" icon="exclamation-triangle" />
          </span>
        </p>
        <template #content>
          <p>Bij gewichten groter dan 23kg worden er mogelijk extra kosten in rekening gebracht.</p>
        </template>
      </b-tooltip>
      <b-input v-model="order.shipment.packages[index].weight" expanded :min="0" :step="0.01" type="number" />
      <p class="control">
        <span class="button is-static">gram</span>
      </p>
      <p v-if="hasMoreThanOnePackage" class="control">
        <b-button aria-label="Pakket verwijderen" title="Pakket verwijderen" type="" @click="removePackage(index)">
          <font-awesome-icon icon="trash" />
        </b-button>
      </p>
    </b-field>
    <b-button
      v-if="selectedCourierMeta?.max_packages > 1"
      :disabled="selectedCourierMeta?.max_packages <= order.shipment.packages.length"
      @click="addPackage"
    >
      <div class="tw-inline-flex tw-items-center tw-gap-1">
        <font-awesome-icon icon="plus" />
        <span>Pakket toevoegen</span>
      </div>
    </b-button>

    <b-notification v-if="shouldShowWeightNotification" class="tw-mt-4" :closable="false" type="is-warning">
      <strong>Let op:</strong> Gewichten bij multi-collo worden bij elkaar opgeteld. Boven de 30kg is het beter (en veel
      goedkoper) om losse labels aan te maken voor FedEx zendigen.
    </b-notification>
  </div>
</template>
