<template>
  <div>
    <h2 class="tw-text-2xl tw-font-semibold">Geschiedenis</h2>
    <b-field label="Selecteer pakket">
      <b-select v-model="selectedPackage" expanded placeholder="Selecteer pakket">
        <option v-for="package_ in label?.shipment?.packages" :key="package_.tracking_number" :value="package_">
          {{ package_.tracking_number }}
        </option>
      </b-select>
    </b-field>
    <ul v-if="selectedPackage?.tracking?.history.length" class="timeline">
      <tracking-history-item
        v-for="entry in history"
        :key="entry.status"
        :entry="entry"
        :tracking_mails="label?.shipment?.tracking_mails"
      />
      <!-- Estimated delivery date - Only show if package has not been delivered yet -->
      <!--      <tracking-history-item-->
      <!--        v-if="selectedPackage?.tracking?.delivery && !history.some(item => item.status === 'delivered')"-->
      <!--        class="is-future"-->
      <!--        hide-timestamp-->
      <!--        icon="calendar"-->
      <!--        tag="Geschatte leverdatum"-->
      <!--      >-->
      <!--        <template #content>-->
      <!--          {{ formatDeliveryDate(selectedPackage.tracking.delivery.start, selectedPackage.tracking.delivery.end) }}-->
      <!--        </template>-->
      <!--      </tracking-history-item>-->
    </ul>
    <div v-else>
      <b-notification :closable="false">
        Geen geschiedenis beschikbaar voor {{ selectedPackage?.tracking_number }}
      </b-notification>
    </div>
  </div>
</template>

<script setup>
  import TrackingHistoryItem from '@/components/TrackingHistoryItem.vue';
  import moment from 'moment';
  import { computed, ref } from 'vue';

  const props = defineProps({
    label: {
      type: Object,
      required: true,
    },
  });

  const selectedPackage = ref(props.label.shipment.packages[0]);

  const formatDeliveryDate = (start, end) => {
    // Parse start and end dates and set locale to Dutch
    const startDate = moment(start).locale('nl');
    const endDate = moment(end).locale('nl');

    switch (true) {
      // If start and end dates are the same day
      case startDate.isSame(endDate, 'day'):
        return `${startDate.format('D MMMM')} tussen ${startDate.format('LT')} en ${endDate.format('LT')}`;

      // If start and end dates are within the same month
      case startDate.isSame(endDate, 'month'):
        return `${startDate.format('D')} - ${endDate.format('D MMMM')}`;

      // If start and end dates span multiple months
      default:
        return `${startDate.format('D MMMM')} - ${endDate.format('D MMMM')}`;
    }
  };

  const history = computed(() => {
    const clonedHistory = structuredClone(selectedPackage.value.tracking?.history);

    // Insert hardcoded events for internal things such as order creation
    clonedHistory.push({
      status: 'order_created',
      location: null,
      first_seen_at: props.label.order_date,
      last_seen_at: props.label.order_date,
    });

    if (selectedPackage.value.tracking.ticket) {
      clonedHistory.push({
        status: 'ticket_created',
        location: null,
        first_seen_at: selectedPackage.value.tracking.ticket.created_at,
        last_seen_at: selectedPackage.value.tracking.ticket.created_at,
      });
    }

    //in the clonedHistory we need to find the status ready and printed and look at the first_seen_at date, if they are within a minute we can combine them
    const readyEvent = clonedHistory.find(event => event.status === 'ready');
    const printedEvent = clonedHistory.find(event => event.status === 'printed');

    if (readyEvent && printedEvent) {
      const readyDate = new Date(readyEvent.first_seen_at);
      const printedDate = new Date(printedEvent.first_seen_at);
      if (Math.abs(readyDate - printedDate) < 60000) {
        // remove ready item and update status of printed item to ready_printed
        printedEvent.status = 'ready_printed';
        clonedHistory.filter(event => event !== readyEvent);
      }
    }

    // Sort by first_seen_at date in ascending order
    clonedHistory.sort((a, b) => new Date(a.first_seen_at) - new Date(b.first_seen_at));

    return clonedHistory;
  });
</script>
