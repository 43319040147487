import Vue from 'vue';
import Vuex from 'vuex';
import account from './modules/account';
import app from './modules/app';
import courier from './modules/courier';
import invoice from './modules/invoice';
import label from './modules/label';
import plan from './modules/plan';
import order from './modules/order';
import contact from './modules/contact';
import shop from './modules/shop';
import auth from './modules/auth';
import ticket from './modules/ticket';
import apiUser from './modules/api_user';
import { UnauthorizedError } from '@/utils/errors';
import { CONFIG_UVDESK_ENABLED } from '@/config';

Vue.use(Vuex);

function authenticationHandlerPlugin(store) {
  const dispatch = store.dispatch;

  store.dispatch = async (type, payload) => {
    try {
      return await dispatch.apply(store, [type, payload]);
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        await dispatch.apply(store, ['auth/login']);
        return await dispatch.apply(store, [type, payload]);
      } else {
        throw e;
      }
    }
  };
}

export default new Vuex.Store({
  plugins: [authenticationHandlerPlugin],
  state: {
    appInitialized: false,
  },
  mutations: {
    setAppInitialized(state, value) {
      state.appInitialized = value;
    },
  },
  modules: { account, app, courier, plan, label, order, shop, auth, invoice, ticket, apiUser, contact },
  actions: {
    async init({ dispatch }) {
      await dispatch('auth/init');
    },
    async afterLogin({ dispatch }) {
      // Wait, because we can't init anything else without the user
      await dispatch('account/init');
      dispatch('app/init');
      await dispatch('courier/init');
      await dispatch('shop/init');
      dispatch('plan/init');
      if (CONFIG_UVDESK_ENABLED) dispatch('ticket/init');
      dispatch('initializeApp');
    },
    initializeApp({ commit }) {
      commit('setAppInitialized', true);
    },
  },
  strict: process.env.NODE_ENV !== 'production',
});
