<script setup>
  import { computed, inject } from 'vue';
  import PickupField from '@/components/form/pickup/PickupField.vue';
  import DynamicField from '@/components/form/DynamicField.vue';
  import { useOrderCourier } from '@/composables/order/useOrderCourier';

  const order = inject('order');
  const errors = inject('errors');

  const { filteredCouriers, shipmentTypes, activeCouriers } = useOrderCourier(order);

  const selectedCourierMeta = inject('selectedCourierMeta');

  const supportsPickupPoints = computed(() => {
    const EXCLUDED_COURIERS = ['logique'];
    return !EXCLUDED_COURIERS.includes(
      filteredCouriers.value.find(c => c.id === order.value.shipment.courier_id)?.service_name.toLowerCase(),
    );
  });

  const canSelectDropOffPoint = computed(() => {
    const addressFields = [order.value.address_1, order.value.city, order.value.zipcode];

    // Count the number of filled address fields
    const filledAddressFields = addressFields.filter(field => !!field).length;

    // Check if courier_id is filled and at least 2 address fields are filled
    return filledAddressFields >= 2;
  });

  const isMyParcelSelected = computed(() => {
    return selectedCourierMeta.name === 'MyParcel' || selectedCourierMeta.value?.name === 'PostNL Innosend';
  });

  const shouldDisable = field => {
    if (!isMyParcelSelected.value) return false;
    if (order.value.shipment.courier_fields.insurance === '0') return false;
    if (field.name !== 'only_recipient' && field.name !== 'signature') return false;
    return true;
  };
</script>
<template>
  <div>
    <b-field
      label="Vervoerder"
      :message="errors.shipment?.courier_id?.messages?.join(' & ')"
      :type="errors.shipment?.courier_id?.type"
    >
      <b-select v-model="order.shipment.courier_id" expanded placeholder="Selecteer vervoerder...">
        <option
          v-for="courier in filteredCouriers"
          :key="courier.id"
          :disabled="!courier.is_active"
          :value="courier.id"
        >
          {{ courier.service_name }}
        </option>
      </b-select>
    </b-field>

    <b-field
      label="Verzendmethode"
      :message="errors.shipment?.shipment_type?.messages?.join(' & ')"
      :type="errors.shipment?.shipment_type?.type"
    >
      <b-select
        v-model="order.shipment.shipment_type"
        :disabled="!order.shipment.courier_id"
        expanded
        placeholder="Selecteer verzendmethode..."
      >
        <option v-for="type in shipmentTypes" :key="type.id" :value="type.id">
          {{ type.name || 'Standaard' }}
        </option>
      </b-select>
    </b-field>

    <b-field
      v-if="supportsPickupPoints"
      label="Afleverpunt"
      :message="errors.pickup_point?.messages?.join(' & ')"
      :type="errors.pickup_point?.type"
    >
      <PickupField v-if="canSelectDropOffPoint" v-model="order.pickup_point" :order="order" />
      <template v-if="!canSelectDropOffPoint" #message>
        Je moet op zijn minst twee adresvelden zoals postcode en adres regel 1 of plaats invullen om een afleverpunt te
        selecteren.
      </template>
    </b-field>

    <template v-if="selectedCourierMeta && selectedCourierMeta.order_fields.length > 0">
      <!-- Make sure fields are not reused between couriers -->
      <dynamic-field
        v-for="field in selectedCourierMeta.order_fields"
        :key="order.shipment.courier_id + '_' + field.name"
        v-model="order.shipment.courier_fields[field.name]"
        :disabled="shouldDisable(field)"
        :field="field"
        :message="errors.shipment?.courier_fields?.[field.name]?.messages?.join(' & ')"
        :type="errors.shipment?.courier_fields?.[field.name]?.type"
      >
      </dynamic-field>
    </template>
  </div>
</template>
